// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './theme/variables.scss';

* {
  font-family: 'Open Sans', sans-serif;
}

.align-right {
  text-align: end;
}

h2 {
  color: $orange;
}

.orange {
  color: $orange;
}

.med-grey {
  color: $medium-grey;
}

.gray-label {
  color: $grey;
  font-size: 14px;
  margin-bottom: auto;
  margin-top: auto;}

.orange-label {
  color: $orange;
  font-size: 14px;
  margin-bottom: 8px;
  width: 94px;
}

.edit-saved-filter {
  right: 6px;
  width: 64px;
}

ion-label.user-label {
  font-size: 14px !important;
}

.underlined-link {
  display: block;
  font-size: 14px;
  text-decoration: underline;
  margin: 12px 0;
}

.submit-button {
  display: block;
  width: 70%;
  margin: 12px auto;
  font-weight: bolder;
  border-radius: 4px;
}

.text-button {
  display: flex;
  justify-content: center;
  align-content: center;
  --box-shadow: none;

  ion-icon {
    color: $orange;
    width: 26px;
    height: 26px;
  }
}

.text-button-label {
  color: $orange;
  font-weight: bolder;
  font-size: 17px;
}

.select-button {
  display: flex;
  justify-content: center;
  align-content: center;

  ion-icon {
    color: $orange;
    width: 26px;
    height: 26px;
  }
}

.select-button-text {
  position: relative;
  bottom: 4px;
  color: $orange;
  font-weight: bolder;
  font-size: 17px;
}

.intro-text {
  margin: 16px 16px;
  text-align: center;

  p {
    font-family: 'Open Sans';
    text-align: center;
    font-size: 14px;
  }
}

.height-50 {
  height: 50vh;
  position: relative;
}

.height-75 {
  height: 75vh;
  position: relative;
}

.h-85-vh {
  height: 85vh;
  position: relative;
}

.height-95 {
  height: 95vh;
  position: relative;
}

.height-100 {
  height: 100vh;
  position: relative;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.p-22 {
  padding: 22px;
}

.p-22-l {
  padding-left: 22px;
}

.p-16-v {
  padding: 0 16px;
}

.p-8 {
  padding: 8px;
}

.m-22 {
  margin: 22px;
}

.m-t-22 {
  margin-top: 22px;
}

.m-r-18 {
  margin-right: 18px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-b-4 {
  padding-bottom: 4px;
}

.bold {
  font-weight: bold;
}

.d-flex {
  display: flex;
}

.font-16px {
  font-size: 16px;
}

ion-card {
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
  width: 100%;
}

ion-icon.home {
  position: absolute;
  right: 10px;
}

ion-icon.orange {
  color: $orange;
  position: absolute;
  right: 0px;
}

ion-icon.orange2 {
  color: $orange;
  position: absolute;
  right: 20px;
}

ion-title {
  font-size: 18px;
}

app-search-bar {
  width: 100%;
}

.left-pad {
  padding-left: 32px;
}

.subtasks {
  margin-left: 10%;
}

.training {
  margin-top: 20%;
}

div.nav-container {
  margin: 12px 12px;

  .side-nav-link-main {
    display: block;
    margin: 46px 16px 16px 16px;
    text-align: start;
    color: white;
    font-size: 1em;
    font-weight: bolder;
    text-decoration: none;
    text-transform: uppercase;
  }

  .side-nav-link-sub {
    display: block;
    margin: 26px 0 0 50px;
    text-align: start;
    color: white;
    font-size: 1em;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
  }
}

ion-list.side-menu-background,
ion-content.side-menu-background,
ion-footer.side-menu-background {
  background-color: var(--ion-color-secondary);
}

ion-item.active > ion-label,
ion-button.active {
  color: $orange !important;
}

.home-menu {
  margin-top: env(safe-area-inset-top); // for ios 11.2 and onwards
  margin-bottom: env(safe-area-inset-bottom);
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.side-menu-bottom {
  margin-bottom: env(safe-area-inset-bottom);
  padding-bottom: 40px;
}

.li-ionic4-datePicker {
  ion-header {
    height: calc(env(safe-area-inset-top) + 60px) !important;
  }
}
.li-ionic4-datePicker {
  ion-footer {
    height: calc(env(safe-area-inset-bottom) + 55px) !important;
  }
}

/*
 * Add scroll bar to select options
 * cf. https://github.com/ionic-team/ionic/issues/17397#issuecomment-496092852
 */
.action-sheet-group::-webkit-scrollbar,
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.action-sheet-group::-webkit-scrollbar-track,
.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.action-sheet-group::-webkit-scrollbar-thumb,
.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.full-screen-modal .modal-wrapper {
  height: 100%;
  width: 100%;
}

ion-button.default-button {
  --border-radius: 4px;
}

a:hover {
  cursor: pointer;
}
